<template>
  <div class="main">
     <loading color="#1989fa" vertical>正在登录...</loading>
  </div>
</template>
<script>
import {Loading, Toast} from 'vant';
import {getQxUserId} from '@/api/performance'
import Cookies from "js-cookie";
export default {
  name: 'Home',
  components: {
     Loading
  },
  data() {
    return {
      userId: '', // 用户id
    }},
  created() {
  },
  mounted:function(){
      this.login()
  },
  methods: {
      // 用户网页授权登录
      login(){
          // sessionStorage.setItem("token", '123')
          const query = this.$route.query
          let code = query.code
          if(code){
              let postData={}
              postData.code=code
              postData.agentId=1000097
              getQxUserId(postData).then(response => {
                  if(response.code===200 || response.code===0){
                      Cookies.set('userId', response.data.userId)
                      sessionStorage.setItem("token", response.data.jwtToken)
                      // 判断跳转路径
                      let path = sessionStorage.getItem("perfPath")
                      let paramData = JSON.parse(sessionStorage.getItem("perfQuery"))
                      this.$router.push({ path: path, query: paramData})
                  }else{
                      Toast.fail('获取userId失败');
                      return false
                  }
              })
          }else{
              let nowUrl = window.location.href;
              let backurl = encodeURIComponent(nowUrl)
              let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwf93ebffd5d31aa7a&redirect_uri=' + backurl + '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
              window.location.href=url
          }
      },
  }
}
</script>

<style scoped>
  .main{
    background-color:#ffffff;
    padding: 20px;
    min-height: 100vh;
  }
</style>
